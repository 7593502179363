import React from "react";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import Box from "../../elements/Box";
import ButtonLink from "../../elements/ButtonLink";
import { StaticImage } from "gatsby-plugin-image";
import { ClassNames } from "@emotion/react";
import { addMediaQuery, breakPoints } from "../../styles/responsive";
import ViewportWrapper from "../ViewportWrapper";
import { useInView } from "react-intersection-observer";
import AnimatedText from "../../elements/AnimatedText";
import AnimatedButton from "../../elements/AnimatedButtonLink/AnimatedButtonLink";

const HomepageBanner = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });
  return (
    <ViewportWrapper ref={ref}>
      <Box
        backgroundColor={"#010303"}
        display="grid"
        gridTemplateColumns={["auto", "auto", "1fr"]}
      >
        <Box
          gridArea={["1/1", "1/1", "1/1/auto/2", "1/1/auto/3"]}
          position="relative"
          alignSelf="center"
          zIndex={10}
          margin="auto"
          width={["90%"]}
        >
          <AnimatedText
            color={colors.white}
            fontSize={[6, 6, 6, 6, 7]}
            fontWeight="bold"
            margin="0"
            as="h1"
            doesAnimate={inView}
          >
            Digital commerce agency
          </AnimatedText>
          <AnimatedText
            color={colors.white}
            fontSize={[2, 2, 2, 3]}
            margin="2rem 0"
            maxWidth="500px"
            doesAnimate={inView}
          >
            We provide data driven design, strategic technical execution, and
            digital commerce transformation
          </AnimatedText>
          <AnimatedButton
            to="/what-we-do"
            variant="primary"
            doesAnimate={inView}
          >
            Check our services
          </AnimatedButton>
        </Box>
        <ClassNames>
          {({ css }) => (
            <StaticImage
              src="../../images/Carousel/camilla.png"
              alt="camilla"
              className={css(imageStyles)}
              layout="fullWidth"
              placeholder="none"
            />
          )}
        </ClassNames>
      </Box>
    </ViewportWrapper>
  );
};

const imageStyles = `
  grid-area: 1/1;
  opacity: 0.5;
  padding: 4rem 0;
  ${addMediaQuery(
    breakPoints.tablet,
    `
    grid-area: 1/2/auto/auto;
    padding: 2rem 0;
    width: 350px;
    opacity: 1;
`
  )}
  ${addMediaQuery(
    breakPoints.tabletLandscape,
    `
    width: 450px;
  `
  )}
  ${addMediaQuery(
    breakPoints.desktop,
    `
    width: 500px;
`
  )}
  ${addMediaQuery(
    breakPoints.desktopMedium,
    `
    width: 600px;
`
  )}
  ${addMediaQuery(
    breakPoints.desktopLarge,
    `
    width: 700px;
`
  )}
`;

export default HomepageBanner;
