import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import AnimatedBox from "../../elements/AnimatedBox";
import Box from "../../elements/Box";
import { BoxProps } from "../../elements/Box/Box";
import Link from "../../elements/Link";
import colors from "../../styles/colors";
import ViewportWrapper from "../ViewportWrapper";

interface ServiceProps {
  children: ReactNode;
  title: string;
  link: string;
  delay?: string;
  inView: boolean;
}

const Service = ({ children, title, link, delay, inView }: ServiceProps) => {
  return (
    <AnimatedBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      key={title}
      doesAnimate={inView}
      delay={delay}
    >
      {children}
      <Link
        color={colors.black}
        to={link}
        hoverUnderline={true}
        marginTop="1rem"
        hoverNormal={true}
        fontSize={[2, 2, 2, 3]}
      >
        {title}
      </Link>
    </AnimatedBox>
  );
};

const imageStyle = `          
    max-width: 40px;
    @media (min-width: 768px) {
      max-width: 70px;
    }`;

const Services = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  return (
    <ViewportWrapper ref={ref}>
      <Box
        display={["grid", "grid", "flex"]}
        width="80%"
        justifyContent="space-evenly"
        gridTemplateColumns="1fr 1fr"
        gridRowGap="2rem"
        margin="4rem auto"
      >
        <Service title="Design" link="design" inView={inView}>
          <StaticImage
            src="../../images/Services/design.png"
            alt="design"
            css={css`
              ${imageStyle}
            `}
          />
        </Service>
        <Service
          title="Development"
          link="magento-development"
          inView={inView}
          delay="0.2s"
        >
          <StaticImage
            src="../../images/Services/development.png"
            alt="design"
            css={css`
              ${imageStyle}
            `}
          />
        </Service>
        <Service
          title="Marketing"
          link="marketing"
          inView={inView}
          delay="0.4s"
        >
          <StaticImage
            src="../../images/Services/marketing.png"
            alt="design"
            css={css`
              ${imageStyle}
            `}
          />
        </Service>
        <Service title="Support" link="support" inView={inView} delay="0.6s">
          <StaticImage
            src="../../images/Services/support.png"
            alt="design"
            css={css`
              ${imageStyle}
            `}
          />
        </Service>
      </Box>
    </ViewportWrapper>
  );
};

export default Services;
