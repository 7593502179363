import React from "react";
import App from "../components/App";
import HomepageBanner from "../components/HomepageBanner/HomepageBanner";
import Services from "../components/Services";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import PageBuilder from "../components/PageBuilder";
import SEO from "../components/SEO";

const GET_HOMEPAGE_SECTIONS = graphql`
  query {
    allContentfulPage(filter: { page: { eq: "Home" } }) {
      edges {
        node {
          section {
            ... on ContentfulSection {
              sectionHeading: heading
              contentfulDescription: description {
                description
              }
              displayGooglePartnerBadge
              usePartnerBadges
              sectionImage: image {
                gatsbyImageData
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              fullWidth
              noMargin
              secondaryHeading
              buttonLink
              buttonText
              buttonVariant
              paragraphFontSize
              reverse
              pageLink
              mobile
              backgroundColor
              icons {
                gatsbyImageData
              }
            }
            ... on ContentfulTestimonials {
              quote {
                quote
              }
              image {
                gatsbyImageData
                description
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulAssociatedBrands {
              backgroundColor
              heading
              headingColor
              maxInRow
              rowGap
              description {
                description
              }
              associatedBrandsImages: images {
                gatsbyImageData
                description
              }
              fullWidth
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulNestedContent {
              sectionGroup {
                ... on ContentfulContentBlock {
                  description {
                    description
                  }
                  heading
                  backgroundColor
                  image {
                    gatsbyImageData
                  }
                }
                ... on ContentfulSection {
                  backgroundColor
                  buttonLink
                  buttonText
                  buttonVariant
                  paragraphFontSize
                  fullWidth
                  noMargin
                  contentfulDescription: description {
                    description
                  }
                  sectionHeading: heading
                  icons {
                    gatsbyImageData
                  }
                  sectionImage: image {
                    gatsbyImageData
                  }
                  mobile
                  reverse
                  pageLink
                  secondaryHeading
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          title
          description
          page
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const HomePage = ({ location }: PageProps) => {
  const homePageSections = useStaticQuery(GET_HOMEPAGE_SECTIONS);
  const {
    section: data,
    image,
    title,
    description
  } = homePageSections?.allContentfulPage?.edges?.[0]?.node;
  return (
    <App location={location}>
      <SEO
        title={title}
        description={description}
        url={location.href}
        image={image?.file?.url}
      />
      <HomepageBanner />
      <Services />
      <PageBuilder data={data} />
    </App>
  );
};

export default HomePage;
